import React, { FC } from 'react'

import { Grid, Typography } from '@mui/material'

import { Actions } from '@components/Actions'
import { ProjectAdres } from '@components/Adres'
import Label from '@components/Label'
import Realisatie from '@components/Realisatie'
import Uitvoerende from '@components/Uitvoerende'
import { formatDate } from '@util/date-util'

import type { Project } from '../types'

export interface Props {
  project: Project
}

export const Gegevens: FC<Props> = ({ project }: Props) => {
  const mapAannemer = (aannemer) => {
    const opdrachtnemer =
      project.mappedOpdrachtnemers && project.mappedOpdrachtnemers[aannemer]

    if (opdrachtnemer) {
      return { opdrachtnemer, aannemer }
    }

    return { aannemer }
  }

  return (
    <Grid sx={{ p: 1, width: '95%', mx: 'auto' }} container spacing={2}>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Type</b>
        </Typography>
        <Typography variant="body2">{project.type}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Processtap</b>
        </Typography>
        <Typography variant="body2">{project.statusText}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Disciplines</b>
        </Typography>
        <Typography variant="body2">{project.uniqDisciplines}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Adres</b>
        </Typography>
        <Typography variant="body2">
          {project && <ProjectAdres {...project} />}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Netbeheerders</b>
        </Typography>
        <Typography variant="body2">
          <>{project.netbeheerders && project.netbeheerders.join(', ')}</>
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Uitvoerende aannemers</b>
        </Typography>
        <Typography variant="body2" component={'span'}>
          {project.aannemers &&
            project.aannemers
              .map(mapAannemer)
              .map(({ opdrachtnemer, aannemer }, idx) => {
                //@ts-ignore
                const isLast = idx === project.aannemers.length - 1

                return (
                  <Uitvoerende
                    key={aannemer}
                    aannemer={aannemer}
                    opdrachtnemer={opdrachtnemer}
                    addComma={!isLast}
                  />
                )
              })}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Aanvraagdatum</b>
        </Typography>
        <Typography variant="body2">
          {formatDate(project.aanvraagDatum)}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Wensdatum klant</b>
        </Typography>
        <Typography variant="body2">{formatDate(project.wensdatum)}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Geplande uitvoering</b>
        </Typography>
        <Realisatie
          date={project.geplandeUitvoeringsdatum as string}
          type={project.geplandeUitvoeringsdatumType}
        />
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Aanvraag compleet</b>
        </Typography>
        <Typography variant="body2">
          {project.isCompleet ? 'Ja' : 'Nee'}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Combi</b>
        </Typography>
        <Typography variant="body2">{project.combi}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography gutterBottom variant="subtitle1">
          <b>Gebied</b>
        </Typography>
        <Typography variant="body2">{project.gebiedscodeLabel}</Typography>
      </Grid>
      {project.type === 'Aanvraag' && (
        <Grid item xs={3}>
          <Typography gutterBottom variant="subtitle1">
            <b>Acties</b>
          </Typography>
          <Actions acties={project.acties} />
        </Grid>
      )}
      {project.labels && project.labels.length > 0 && (
        <Grid item xs={3}>
          <Typography variant="subtitle1">
            <b>Labels</b>
          </Typography>
          {project.labels.map((label) => (
            <Label key={label} text={label} />
          ))}
        </Grid>
      )}
    </Grid>
  )
}
