import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'

import { copyBeheerAanvraag, isCopyAanvraagLoading } from '../state'

const initialData = {
  newAanvraagID: '',
  aanvraagDatum: '',
  wensWeek: '',
  wensJaar: '',
}

export const AanvraagKopieren: FC = () => {
  const dispatch = useDispatch()
  const isCopying = useSelector(isCopyAanvraagLoading)
  const [aanvraagID, setAanvraagID] = useState('')
  const [data, setData] = useState(initialData)

  const onClickKopieren = () => {
    dispatch(
      copyBeheerAanvraag({
        aanvraagID,
        data,
      }),
    )
  }

  const onClickReset = () => {
    setAanvraagID('')
    setData(initialData)
  }

  return (
    <Box sx={{ p: 1, width: '95%', mx: 'auto' }}>
      <Grid>
        <Grid item sx={{ mb: 2 }} xs={12}>
          <FormControl>
            <TextField
              label="Huidig AanvraagID"
              onChange={(ev) => setAanvraagID(ev.target.value)}
              value={aanvraagID}
            />
          </FormControl>
        </Grid>
        <Typography variant="h4" sx={{ mb: 2, mt: 4 }}>
          Nieuwe gegevens
        </Typography>
        <Grid item sx={{ mb: 2 }}>
          <FormControl sx={{ mr: 2 }}>
            <TextField
              label="Nieuw AanvraagID"
              onChange={(ev) =>
                setData({ ...data, newAanvraagID: ev.target.value })
              }
              value={data.newAanvraagID}
            />
          </FormControl>
          <FormControl>
            <TextField
              label="AanvraagDatum"
              onChange={(ev) =>
                setData({ ...data, aanvraagDatum: ev.target.value })
              }
              value={data.aanvraagDatum}
            />
          </FormControl>
        </Grid>
        <Grid item sx={{ mr: 2 }}>
          <FormControl>
            <TextField
              label="Wensjaar"
              onChange={(ev) => setData({ ...data, wensJaar: ev.target.value })}
              value={data.wensJaar}
            />
          </FormControl>
          <FormControl sx={{ mb: 2 }}>
            <TextField
              label="Wensweek"
              onChange={(ev) => setData({ ...data, wensWeek: ev.target.value })}
              value={data.wensWeek}
            />
          </FormControl>
        </Grid>
      </Grid>

      {!isCopying && aanvraagID && (
        <>
          <ButtonGroup sx={{ mt: 3 }}>
            <Button variant="contained" onClick={onClickKopieren}>
              Kopieren
            </Button>
            <Button variant="outlined" onClick={onClickReset}>
              Reset
            </Button>
          </ButtonGroup>
        </>
      )}

      {isCopying && (
        <LinearProgress
          sx={{
            mt: 2,
            height: '3px',
            backgroundColor: 'rgb(238, 238, 238)',
            background:
              'linear-gradient(to right, rgba(61, 108, 180, 1) 0%, rgba(79, 186, 219, 1) 100%)',
          }}
        />
      )}
    </Box>
  )
}
