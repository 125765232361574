import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material'

import { Tile } from '../../../aansluiting/dashboard/components/werklijsten/Tile'
import type { RootState } from '../../../store'
import { initialState as initialStateActiviteiten } from '../../activiteiten/state'
import { initialState as initialStateAssignedBijlagen } from '../../bijlagen/state'
import { fetchWerklijsten, getWerklijsten } from '../state'
import type { Filters, Werklijst } from '../types'

export interface Props {
  filters: Filters
  onClickTile: (query: any, url: any) => void
}

export const Werklijsten: FC<Props> = ({ filters, onClickTile }) => {
  const dispatch = useDispatch()
  const {
    werklijsten: { bijlagen, activiteiten },
  } = useSelector((state: RootState) => ({
    werklijsten: getWerklijsten(state),
  }))

  const onClickTileBijlagen = (werklijst: Werklijst) => {
    const { label } = werklijst

    const query = {
      filters: {
        ...initialStateAssignedBijlagen.query.filters,
        ...filters,
        documentsoort: [label],
        assignedTo: filters.organisatie ? [filters.organisatie] : [],
      },
      page: initialStateAssignedBijlagen.query.page,
      sort: initialStateAssignedBijlagen.query.sort,
    }

    onClickTile(query, '/hoofdleiding/bijlagen')
  }

  const onClickTileActiviteiten = (werklijst: Werklijst) => {
    const { code, label } = werklijst

    const query = {
      filters: {
        ...initialStateActiviteiten.query.filters,
        ...filters,
        type: [{ code, label }],
        assignedTo: filters.organisatie ? [filters.organisatie] : [],
      },
      page: initialStateActiviteiten.query.page,
      sort: initialStateActiviteiten.query.sort,
    }

    onClickTile(query, '/hoofdleiding/activiteiten')
  }

  useEffect(() => {
    dispatch(fetchWerklijsten({ filters }))
  }, [dispatch, filters])

  return (
    <>
      <Accordion defaultExpanded key="bijlagen">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontSize: '24px', pl: '24px' }}>
            Bijlagen
          </Typography>
        </AccordionSummary>

        <AccordionDetails sx={{ p: '50px' }}>
          <Grid container spacing={2}>
            {bijlagen.map((werklijst) => (
              <Grid item key={werklijst.label}>
                <Tile werklijst={werklijst} onClickTile={onClickTileBijlagen} />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded key="Activiteiten">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontSize: '24px', pl: '24px' }}>
            Activiteiten
          </Typography>
        </AccordionSummary>

        <AccordionDetails sx={{ p: '50px' }}>
          <Grid container spacing={2}>
            {activiteiten.map((werklijst) => (
              <Grid item key={werklijst.label}>
                <Tile
                  werklijst={werklijst}
                  onClickTile={onClickTileActiviteiten}
                />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
